import React from 'react'
import './Marquee.css'
import schoolData from '../assets/data/schoolData'

const Marquee = (props) => {
    const orbCount = props.usedBy.length
    // width should equal: (orb width * (number of orbs)) + (orb side margin * (number of orbs * 2))
    const marqueeWidth = 150 * (orbCount) + (25 * (orbCount * 2))
    const duration = marqueeWidth / 67
    return (
        <div className='marquee-container'>
        <h1 className='marquee-title'>This item is used by</h1>
        <div className="marquee" style={{width: `${marqueeWidth}px`}}>
        <div className="marquee-inner" style={{animation: `marquee ${duration}s linear infinite`}}>
          <span className='span'>
            {props.usedBy.map((item, index) => {
                return <div className='orb'><img className='marquee-image' src={schoolData[item - 1].image} alt={schoolData[item - 1].name}/></div>
            })}
          </span>
          <span className='span'>
            {props.usedBy.map((item, index) => {
                return <div className='orb'><img className='marquee-image' src={schoolData[item - 1].image} alt={schoolData[item - 1].name}/></div>
            })}
          </span>
      
        </div>
      </div>
      </div>
    )
}

export default Marquee