import React from 'react'

import SubCategoryItem from './SubCategoryItem'
import '../ui/UiList.css'

const SubCategoryList = props => {
    if (props.items.length === 0) {
        return <h3>Nothing found in this category.</h3>
    }

    return (
        <ul className="category-grid">
            {props.items.map(item => <SubCategoryItem
                className='category-block'
                key={item.id}
                id={item.id}
                name={item.name}
                urlName={item.urlName}
                image={item.coverImg}
                category={item.main}
            />)}
        </ul>
    )
}

export default SubCategoryList