let schoolData = [
    {
        id: 1,
        name: 'Louisiana State University',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FLouisiana-State-University.jpg?alt=media&token=7707c78e-4f5b-4636-8e46-26753845b9ba'
    },
    {
        id: 2,
        name: 'Clemson University',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FClemson-University.jpg?alt=media&token=697b85f0-f072-4d77-9918-8da5900c285d'
    },
    {
        id: 3,
        name: 'Ohio State University',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FOhio-State-University.jpg?alt=media&token=5a41a1b1-c051-4efb-a0e0-c9be08f815a0'
    },
    {
        id: 4,
        name: 'University of Georgia',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FUniversity-of-Georgia.jpg?alt=media&token=8a42472a-dfb8-497c-ab84-f24fca888533'
    },
    {
        id: 5,
        name: 'Florida State University',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FFlorida-State-University.jpg?alt=media&token=54ec5c8b-6f06-4b1d-82a7-70b317bb87e3'
    },
    {
        id: 6,
        name: 'University of South Carolina',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FUniversity-of-South-Carolina.jpg?alt=media&token=02b08a3b-2eb7-4352-9aba-9297d8386a1e'
    },
    {
        id: 7,
        name: 'University of North Carolina',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FUniversity-of-North-Carolina.jpg?alt=media&token=59757d33-9222-4543-b005-6b27ccbee3ad'
    },
    {
        id: 8,
        name: 'Appalachian State University',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FAppalachian-State-University.jpg?alt=media&token=bffb6798-2ab5-4655-8252-3ac2ac4c2e82'
    },
    {
        id: 9,
        name: 'University of Southern California',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FUniversity-of-Souther-California.jpg?alt=media&token=c8e35ceb-5845-4a02-a688-2a42cdcabbb1'
    },
    {
        id: 10,
        name: 'Mississippi State University',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FMississippi-State-University.jpg?alt=media&token=a5614f65-613a-4ade-ac54-da539a20cbaa'
    },
    {
        id: 11,
        name: 'Texas Tech University',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FTexas-Tech-University.jpg?alt=media&token=2fc1768e-ac5d-4840-9544-2a04953b157a'
    },
    {
        id: 12,
        name: 'Georgia Tech University',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FGeorgia-Tech-University.jpg?alt=media&token=6e9059d7-a222-4865-a13a-5a7d6023ae84'
    },
    {
        id: 13,
        name: 'Liberty University',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FLiberty-University.jpg?alt=media&token=b5bacfe6-85c5-4af2-8e75-6f7cf52004bb'
    },
    {
        id: 14,
        name: 'University of Louisville',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FUniversity-of-Louisville.jpg?alt=media&token=57ffe6d9-41be-46d2-8cb5-1ecce987c784'
    },
    {
        id: 15,
        name: 'University of Houston',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FUniversity-of-Houston.jpg?alt=media&token=775c901a-1f31-40e9-bbda-d9295d03cf82'
    },
    {
        id: 16,
        name: 'Miami University',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FMiami-University.jpg?alt=media&token=43dd9c70-8867-4aee-8e86-f6ede7cee933'
    },
    {
        id: 17,
        name: 'Northwestern University',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FNorthwestern-University.jpg?alt=media&token=023ac57e-fa28-4173-8050-2971341cbd09'
    },
    {
        id: 18,
        name: 'Southern Methodist University',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FSouthern-Methodist-University.jpg?alt=media&token=e0714001-1462-4747-ae63-8c2a47d35b71'
    },
    {
        id: 19,
        name: 'University of Central Arkansas',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FUniversity-of-Central-Arkansas.jpg?alt=media&token=173f7415-d0cb-4dc3-a435-673d1dd07979'
    },
    {
        id: 20,
        name: 'Grambling State University',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FGrambling-State-University.jpg?alt=media&token=c90274e8-70f8-4c84-9cf5-623235493747'
    },
    {
        id: 21,
        name: 'University of Arkansas Pine Bluff',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FUniversity-of-Arkansas-Pine-Bluff.jpg?alt=media&token=af8d90bd-9f89-4560-ac2e-7ffa611f16dc'
    },
    {
        id: 22,
        name: 'Saint Johns University',
        image: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/schools%2FSaint-Johns-University.jpg?alt=media&token=effba060-7880-45af-873b-5f3fa36c81d1'
    }
]

export default schoolData