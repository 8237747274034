const categoryData = [
    {
        id: 'c1',
        name: 'Football',
        urlName: 'Football',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbrute-sled-9712.JPG?alt=media&token=178d2637-5361-4f03-bc4d-a589ea121834'
    },{
        id: 'c2',
        name: 'Baseball',
        urlName: 'Baseball',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpro-series-pitchers-protective-screen-pp58st.jpg?alt=media&token=c48a4fde-0b62-4980-8625-df0fff402827'
    },{
        id: 'c3',
        name: 'Track & Field',
        urlName: 'Track and Field',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-pit-pv222732.jpg?alt=media&token=0462e818-3ba8-4041-956b-a4cd006d69f1'
    },{
        id: 'c4',
        name: 'Chairs & Stools',
        urlName: 'Chairs and Stools',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-group.jpg?alt=media&token=72685df4-912e-4ef2-8c1f-818d506839c6'
    },{
        id: 'c5',
        name: 'Facility Branding',
        urlName: 'Facility Branding',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage5.jpg?alt=media&token=be851547-a220-466b-bf77-2e6406c011cc'
    },{
        id: 'c6',
        name: 'Protective Padding',
        urlName: 'Protective Padding',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Finterior-wall-padding-wp32622.jpg?alt=media&token=e5f18395-f31d-4233-96ca-fedc36192462'
    },{
        id: 'c7',
        name: 'Windscreen',
        urlName: 'Windscreen',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws.jpg?alt=media&token=aad36623-e15a-4c64-a6f3-2fd420f9c467'
    },{
        id: 'c8',
        name: 'Gym',
        urlName: 'Gym',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdivider-curtains-gdc193.jpg?alt=media&token=ea70e4f0-1869-4670-a30e-250c8a7bd7d5'
    },{
        id: 'c9',
        name: 'Training Mats',
        urlName: 'Training Mats',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdivider-curtains-gdc193.jpg?alt=media&token=ea70e4f0-1869-4670-a30e-250c8a7bd7d5'
    }
]

export default categoryData