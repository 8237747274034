import React from 'react'
import './ImageHero.css'
import { Link } from 'react-router-dom'
import { useMediaQuery } from "@material-ui/core"

const ImageHero = props => {

    // variable for using a media query
    const isSmall = useMediaQuery('(max-width: 600px)');
    const isMedium = useMediaQuery('(max-width: 1000px)');

    return (
        <div className={isSmall ? 'container-sm' : isMedium ? 'container-md' : 'container-lg'}>
            <Link to={props.link}>
                <img className='image-hero-img skeleton' src={props.image} alt={props.imgAlt} width='1200' height='1200' loading='lazy' />
                <div className={props.middle ? 'text_container_middle' : 'text_container_bottom'}>
                    <h1 className={`header ${props.darkTxt ? 'darkTxt' : 'lightTxt'}`}>{props.header}</h1>
                    <p className={`subheader ${props.darkTxt ? 'darkTxt' : 'lightTxt'}`}>{props.subheader}</p>
                </div>
            </Link>
        </div>
    )
}

export default ImageHero