import React from 'react'
import './ContactBar.css'

const ContactBar = ({setShowContactForm}) => {
    return (
        <div className='contact-bar-div'>
            <button type='button' className='contact-bar-button' onClick={() => setShowContactForm(prev => prev === true ? false : true)}>Get Quote</button>
            <a className='contact-bar-button' href='tel:1-800-438-6028' title='phone'>Call Now</a>
        </div>
    )
}

export default ContactBar