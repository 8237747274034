import React from 'react'
import { Link } from 'react-router-dom'

const SubCategoryItem = props => {
    return (
        <Link to={`/${props.category}/${props.urlName}/products`}>
            <li>
                <h3>{props.name}</h3>
            </li>
        </Link>
    )
}

export default SubCategoryItem