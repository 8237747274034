import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import { Link } from 'react-router-dom'
// used to modify values
import { useDispatch } from 'react-redux'
// import actions
import { updateItemObject } from '../features/itemObject'
import './ProductItem.css'

const ProductItem = props => {

    // variable for using a media query
    const isNavSmall = useMediaQuery('(max-width: 599px)')
    const isNavMed = useMediaQuery('(max-width: 790px)')

    const dispatch = useDispatch()

    const skuCount = () => {
        let skus = props.item.options.filter(item => item.includes(':'))
        return skus.length
    }

    const shortenDescription = () => {
        let letters = props.description.split('')
        letters = letters.slice(0, 150)
        return letters.join('') + '...'
    }

    const renderDetails = () => {
        if (props.item.includes.length >= 2) {
            return <ul className="product-card-details">
                        {props.item.includes.map((item, index) => {
                            if (index < 3) {
                                return <li className='product-card-details-li' key={item}>{item}</li>
                            } else if (index === 4) {
                                return <li className='product-card-details-more'>+ more</li>
                            } else {
                                return
                            }
                        })}
                    </ul>
        } else {
            return <ul className="product-card-details">
                    {props.details.map((item, index) => {
                        if (index < 4) {
                            return <li className='product-card-details-li' key={item}>{item}</li>
                        } else {
                            return
                        }
                    })}
                </ul>
        }
    }

    return (
        <Link to={`/${props.sku}/item`} className={isNavSmall ? 'product-item-sm' : isNavMed ? 'product-item-md' : 'product-item'} onClick={() => {
                window.scrollTo(0,0)
                dispatch(updateItemObject(props.item))
            }
        }>
            <div className='product-item-img-div'>
                <img className='product-card-image skeleton' src={props.image} width='1200' height='600' loading='lazy' />
            </div>
            <p className='product-card-name'>{props.name}</p>
            {renderDetails()}
            {!isNavSmall && !isNavMed ?  <p className='product-card-description'>{shortenDescription()}</p> : null}
        </Link>
    )
}

export default ProductItem