const documentData = [
    {
        id: 1,
        title: 'New Dealer Credit Application',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/documents%2Fnew-dealer-credit-app-thumbnail.jpg?alt=media&token=e096a685-1472-40d7-b83a-40423462d753',
        url: 'https://issuu.com/fisherathleticequipment/docs/new-dealer-credit-application?fr=sODIxNzM3MDQ0Mg'
    },
    {
        id: 2,
        title: 'Wall Panel Measuring',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/documents%2Fwall-panel-measuring-guide.jpg?alt=media&token=0f6979f0-0400-481a-9ac1-41b672c606bf',
        url: 'https://issuu.com/fisherathleticequipment/docs/2024_protectivepadding_how_to_measure_page?fr=sZWZkNDY4NjM2OTg'
    },
    {
        id: 3,
        title: 'Wall Panel Maintenance',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/documents%2Fwall-panel-maintenance-thumbnail.jpg?alt=media&token=39cff785-6e6d-430d-88c1-c74606a00b14',
        url: 'https://issuu.com/fisherathleticequipment/docs/wall-panel-maintenance?fr=sMjg2ODM3MDQ0Mg'
    }
]

export default documentData