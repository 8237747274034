import React, { useEffect, useState } from 'react'
// used to access values
import { useSelector } from 'react-redux'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

import './ItemImages.css'
import VideoModal from '../VideoModal';

const UiItemCard = props => {
    // Redux state
    const itemObject = useSelector((state) => state.item.value)
    // React state
    const [isOpen, setIsOpen] = useState(false)
    const [imgIndex, setImgIndex] = useState(0)
    const [imgLimit, setImgLimit] = useState(3)
    const [mainImg, setMainImg] = useState(props.image)
    const [isVideoMain, setIsVideoMain] = useState(false);
    const [videoModalSrc, setVideoModalSrc] = useState(null);
    const [showVideoModal, setShowVideoModal] = useState(false);

    useEffect(() => {
        setIsOpen(false)
        setImgIndex(0)
        setImgLimit(3)
        setMainImg(props.image)
        setIsVideoMain(false)
    }, [itemObject])

    let videosLength = props?.videos?.length ?? 0

    let seeMoreBtnContent = videosLength > 0 ? 'See More Images and Videos' : 'See More Images'

    let multipleMediaTypes = props.images.length + videosLength > 1

    const handleSeeMoreImg = () => {
        if (imgLimit === 60) {
            setImgLimit(3)
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        } else {
            setImgLimit(60)
        }
    }

    const handleMainImg = (event, isVideo = false) => {
        setImgIndex(event.target.id)
        setMainImg(event.target.src)
        if (isVideo) {
            setIsVideoMain(true);
        } else {
            setIsVideoMain(false);
        }
    }

    const handleVideoClick = () => {
        setIsOpen(false)
        setShowVideoModal(true)
    }

    return (
        <div className="item-images-div">
            <div className="item-main-img-container">
                {isVideoMain ? 
                    (<>
                        <img className='skeleton item-main-img' src={mainImg} width='1200' height='600' onClick={handleVideoClick} />
                        <PlayCircleOutlineIcon id="video-icon-lg" color='primary' />
                    </>) :
                    <img className='skeleton item-main-img' src={mainImg} width='1200' height='600' onClick={() => setIsOpen(true)} />
                }
            </div>
            {multipleMediaTypes ?
                <div className="item-img-grid">
                    {props.images.map((item, index) => {
                        if (index < imgLimit) {
                            return <img className="item-img-grid-single skeleton" id={index}
                                        src={item}
                                        onMouseEnter={(event) => handleMainImg(event)} 
                                        onClick={(event) => {
                                            handleMainImg(event)
                                            setIsOpen(true)
                                        }} 
                                        width='1200' height='600'
                                    />
                        }
                        return null
                    })}
                    {videosLength > 0 && props.videos.map((item, index) => {
                        if (index + props.images.length < imgLimit)
                        return (
                            <div id='item-video-container'>
                                <img className="item-img-grid-single skeleton" id={index}
                                    src={item.image}
                                    onMouseEnter={(event) => {
                                        handleMainImg(event, true)
                                    }} 
                                    onClick={(event) => {
                                        handleMainImg(event, true)
                                        setVideoModalSrc(item.src)
                                        handleVideoClick(item.image)
                                    }}
                                    onMouseOver={() => setVideoModalSrc(item.src)}
                                    width='1200' height='600'
                                />
                                <PlayCircleOutlineIcon id="video-icon" color='primary' />
                            </div>
                        )
                    })}
                </div> : null}
            {props.images.length + videosLength > 3 ? <button onClick={handleSeeMoreImg} className="seeMoreImg">{imgLimit === 3 ? seeMoreBtnContent : 'x'}</button> : null}
            
            {isOpen && (
                <Lightbox
                    imageTitle={props.name}
                    mainSrc={props.images[imgIndex]}
                    nextSrc={props.images[(imgIndex + props.images.length + 1) % props.images.length]}
                    prevSrc={props.images[(imgIndex + props.images.length - 1) % props.images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => {
                        setImgIndex(prev => (prev + props.images.length - 1) % props.images.length)
                    }}
                    onMoveNextRequest={() => {
                        setImgIndex(prev => (prev + props.images.length + 1) % props.images.length)
                    }}
                />
            )}

            {showVideoModal && <VideoModal src={videoModalSrc} setShowVideoModal={setShowVideoModal} setIsVideoMain={setIsVideoMain} />}

        </div>
    )
}

export default UiItemCard