import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import './DocumentGrid.css'
import DocumentCard from './DocumentCard'

const DocumentGrid = (props) => {
    // variable for using a media query
    const isNavSmall = useMediaQuery('(max-width: 599px)')
    const isNavMed = useMediaQuery('(max-width: 790px)')

    return (
        <div className={`${isNavSmall ? 'document-grid-div-sm' : isNavMed ? 'document-grid-div-md' : 'document-grid-div-lg'}`}>
            {props.documents.map(item => <DocumentCard key={item.id} image={item.imageUrl} title={item.title} url={item.url} />)}
        </div>
    )
}

export default DocumentGrid