import React from 'react'
import './Categories.css'

import BackButton from '../ui/BackButton'
import CategoryList from '../subComponents/CategoryList'
import categoryData from '../../assets/data/categoryData'
import IntroSection from '../subComponents/IntroSection'

const Categories = props => {
    return (
        <div className='category-div'>
            <IntroSection header={props.header} subHeader={`Choose a category.`} />
            <CategoryList items={categoryData} />
        </div>
    )
}

export default Categories