import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom'
import Logo from './Logo'
import categoryData from '../../assets/data/categoryData'

import './TopNav.css'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const TopNav = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProductsMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleCategoryLink = (item) => {
    if (item.urlName === 'Windscreen') {
      return `/Baseball/Windscreen/products`
    }
    return `/${item.urlName}/subcategories`
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu className='topnav-products-menu'
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <ul onMouseLeave={handleMenuClose}>
        {categoryData.map(item => {
            return <Link to={handleCategoryLink(item)} key={item.id}>
                        <MenuItem className='topnav-products-menu-item' onClick={handleMenuClose}>{item.name.toUpperCase()}</MenuItem>
                    </Link>
        })}
        </ul>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  // Mobile menu
  const renderMobileMenu = (
    <Menu className='topnav-products-menu-mobile'
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link to='/'>
        <MenuItem onClick={handleMobileMenuClose}>
            <p>HOME</p>
        </MenuItem>
      </Link>
      <MenuItem onClick={handleProductsMenuOpen}>
        <p
        aria-label="Products"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true">
            PRODUCTS
        </p>
      </MenuItem>
      <Link to='/catalogs'>
        <MenuItem onClick={handleMobileMenuClose}>
          <p>CATALOGS</p>
        </MenuItem>
      </Link>
      <Link to='/contact'>
        <MenuItem onClick={handleMobileMenuClose}>
          <p>CONTACT</p>
        </MenuItem>
      </Link>
    </Menu>
  );

  return (
    <div className={`${classes.grow} topnav-div`}>
      <AppBar className='appbar' position='fixed'>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
              <Link className='topnav-logo' to="/">
                <Logo />
              </Link>
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Link to='/'>
                    <p className='topnav-typography'>HOME</p>
            </Link>
                  <p className='topnav-typography' 
                    aria-label='Products' 
                    aria-controls={menuId} 
                    aria-haspopup='true' 
                    onClick={handleProductsMenuOpen}
                    onMouseEnter={handleProductsMenuOpen}
                    >
                      PRODUCTS
                    </p>
            <Link to='/catalogs'>
                    <p className='topnav-typography'>CATALOGS</p>
            </Link>
            <Link to='/contact'>
                    <p className='topnav-typography'>CONTACT</p>
            </Link>
            <Link to='/search'>
                <SearchIcon className='topnav-icon' />
            </Link>
          </div>
          <div className={classes.sectionMobile}>
            <MenuItem onClick={handleMobileMenuClose}>
              <Link to='/search'>
                <SearchIcon className='topnav-icon' />
              </Link>
            </MenuItem>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

export default TopNav