import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useMediaQuery } from "@material-ui/core"
import { useDispatch } from 'react-redux'
// import actions
import { updateItemObject } from '../../features/itemObject'

import productData from '../../assets/data/productData'
import ItemImages from '../ui/ItemImages'
import ItemDetails from '../ui/UiItemDetails'
import Marquee from '../Marquee'
import SuggestedItems from '../SuggestedItems'
import ContactBar from '../ContactBar'
import ContactForm from '../subComponents/ContactForm'
import Categories from './Categories'
import './Item.css'

const Item = props => {
  const [showContactForm, setShowContactForm] = useState(false)
  
  const dispatch = useDispatch()

  const urlItem = useParams().item;
  const itemData = productData.filter((item) => item.sku === urlItem);
  
  let showMarquee = false
  if (itemData[0].usedBy.length >= 6) {
    showMarquee = true
  }

  useEffect(() => {
    dispatch(updateItemObject(itemData))
  },[])

  useEffect(() => {
    document.title = `${itemData[0].name} - Fisher Athletic`
  })

  useEffect(() => {
    window.scrollTo(0 ,0)
  },[])

  // variable for using a media query
  const isSmall = useMediaQuery('(max-width: 600px)');
  const isMedium = useMediaQuery('(max-width: 1000px)');

  const divSize = isSmall ? 'item-small-div' : isMedium ? 'item-medium-div' : 'item-large-div'

  // Fisher-Yates shuffle
  const shuffle = (array) => {
    return array
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
  }

  const suggestionAlgo = (data) => {
    let category = itemData[0].category
    // breaks multiple categories into an array
    let categoryArr = category.split(' ')
    let subCategory = itemData[0].subCategory
    let subCategoryArr = subCategory.split(' ')
    let categoryItems = productData.filter(item => {
      let matchFound = false
      let itemArr = item.category.split(' ')
      for (let i = 0; i < categoryArr.length; i++) {
        if (itemArr.includes(categoryArr[i])) {
          matchFound = true
        }
      }
      return matchFound
    })
    let subCategoryItems = productData.filter(item => {
      return subCategory.includes(item.subCategory)
    })
    let subCategoryItemCount = subCategoryItems.length
    let categoryItemCount = categoryItems.length
    let otherSubItems = subCategoryItems.filter(item => item.sku != urlItem)
    let otherCatItems = categoryItems.filter(item => item.sku != urlItem)
    
    if (subCategoryItemCount >= 7) {
      // otherItems is all items except for the current item
      let shuffledItems = shuffle(otherSubItems)
      return shuffledItems.slice(0, 6)
    } else {
      let shuffledSubItems = shuffle(otherSubItems)
      let shuffledCatItems = shuffle(otherCatItems)
      shuffledCatItems.forEach(item => {
        shuffledSubItems.push(item)
      })
      // removes duplicates from the array
      shuffledSubItems = [...new Set(shuffledSubItems)]
      return shuffledSubItems.slice(0, 6)
    }
  }

  const suggestedItemsData = suggestionAlgo(urlItem)

  return (
    <div className='item-container'>
      <div className={divSize}>
        <div id={isSmall ? 'item-actions-sm' : isMedium ? 'item-actions-md' : 'item-actions-lg'}>
        <h1 className="item-name">{itemData[0].name}</h1>
        <ItemImages
          sku={itemData[0].sku}
          name={itemData[0].name}
          image={itemData[0].image}
          images={itemData[0].images}
          videos={itemData[0]?.videos}
          description={props.sku}
        />
        <ContactBar setShowContactForm={setShowContactForm} />
        {showContactForm ? <ContactForm /> : null}
        </div>
        <ItemDetails
          sku={itemData[0].sku}
          name={itemData[0].name}
          description={itemData[0].description}
          details={itemData[0].details}
          options={itemData[0].options}
          includes={itemData[0].includes}
        />
      </div>
      {showMarquee ? <Marquee usedBy={itemData[0].usedBy} /> : null}
        <SuggestedItems items={suggestedItemsData}  />
        <Categories header='Search by category' />
    </div>
  );
}

export default Item