import React from 'react'
import { useMediaQuery } from '@material-ui/core'

import ProductItem from './ProductItem'
import GroupCard from './subComponents/GroupCard'
import Categories from './pages/Categories'
import Search from './Search'
import './ProductList.css'

const ProductList = props => {

    // variable for using a media query
    const isNavSmall = useMediaQuery('(max-width: 599px)')
    const isNavMed = useMediaQuery('(max-width: 790px)')

    if (props.items.length === 0) {
        return (
            <div>
                <Search />
                <p className='no-result-message'>We couldn't find anything for "{props.query}". Try to refine your search or browse through our product categories.</p>
                <Categories header='Search by category' />
            </div>

        )
    }

    let groupCount = {}
    const getGroupCount = () => {
        props.items.map((item, index) => {
            let upperCaseSubCategory = item.subCategory.toUpperCase()
            if (Object.keys(groupCount).length === 0) {
                groupCount[`${item.group}`] = 1
            } else if (Object.keys(groupCount).includes(`${item.group}`) && upperCaseSubCategory.includes(props.subCategory)) {
                groupCount[`${item.group}`]++
            } else {
                groupCount[`${item.group}`] = 1
            }
        })
    }
    getGroupCount()

    let groupList = []
    const renderItems = () => {
         return props.items.map(item => {
            if (props.isSearch) {
                return <ProductItem
                            key={item.id}
                            id={item.id}
                            name={item.name}
                            sku= {item.sku}
                            details={item.details}
                            description={item.description}
                            category={item.category}
                            subCategory={item.subCategory}
                            urlCategory={item.urlCategory}
                            image={item.image}
                            item={item} />
            } else {
                if (groupCount[`${item.group}`] > 1 && (item.group != '' && props.inGroup === false) && groupList.includes(`${item.group}`) === false) {
                    groupList.push(`${item.group}`)
                    return <GroupCard group={item.group} subCategory={item.subCategory} image={item.image} itemCount={groupCount[`${item.group}`]} name={item.name} category={item.category} item={item} />
                } else if(groupList.includes(`${item.group}`)) {
                    return null
                } else {
                    return <ProductItem
                                key={item.id}
                                id={item.id}
                                name={item.name}
                                sku= {item.sku}
                                details= {item.details}
                                description={item.description}
                                category={item.category}
                                subCategory={item.subCategory}
                                urlCategory={item.urlCategory}
                                image={item.image}
                                item={item} />
                }
            }
        })
    }

    return (
        <ul className={isNavSmall ? 'prodListGrid-sm' : isNavMed ? 'prodListGrid-md' : 'prodListGrid'}>
            {renderItems()}
        </ul>
    )
}

export default ProductList