import { createSlice } from '@reduxjs/toolkit'

// This is my reducer
export const itemSlice = createSlice({
    // name of state
    name: 'itemObject',
    initialState: {value: {
        id: 'p1',
        name: 'Tackle Wheel 28"',
        sku: 'tw2814',
        description: 'Tackle wheel teaches players to adjust their angle in pursuit. Teach open field tackling without bodies. Reduce injuries by eliminating repetitions. Teaches players to wrap up in open field. 22 oz. coated vinyl in center and outside. 19 oz. coated vinyl on faces of wheel.',
        details: [
            'SKU: TW2814',
            '28" Outside diam. x 14" Inside diam.',
            '8" Thick.',
            'Rebond Foam.',
            'Weight: 21 lbs'
        ],
        options: [''],
        category: 'football',
        subCategory: 'dummies',
        group: 'Tackle Wheels',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw2814-HQ1.jpg?alt=media&token=989abcd1-cf7a-4899-8176-a2d9db62ae6d",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw2814-HQ1.jpg?alt=media&token=989abcd1-cf7a-4899-8176-a2d9db62ae6d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw2814-HQ2.jpg?alt=media&token=3a37ddfa-efc0-4e3e-b63a-179a7d92b6b8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw2814-HQ3.jpg?alt=media&token=c7a2216a-e88d-463a-9011-63311223dc26",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheels-HQ2.jpg?alt=media&token=505f449a-58db-4629-9ae0-701be6de40d5"
        ]
    }},
    reducers: {
        // actions that can update state
        updateItemObject: (state, action) => {
            // change current state to whatever is in the payload
            state.value = action.payload
        }
    }
})

// export actions
export const { updateItemObject } = itemSlice.actions

export default itemSlice.reducer