import React, { useEffect, useState, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import './VideoModal.css';

export default function VideoModal({src, setShowVideoModal, setIsVideoMain}) {
  const [open, setOpen] = useState(true);
  const startY = useRef(null);
  const modalRef = useRef(null);

  const handleTouchStart = (event) => {
    startY.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    if (!startY.current) {
      return;
    }

    const deltaY = event.touches[0].clientY - startY.current;

    if (deltaY < -80) {
      handleClose()
      startY.current = null;
    }
  };

  const handleTouchEnd = () => {
    startY.current = null;
  };

  useEffect(() => {
    setOpen(true)
    if (modalRef.current) {
        modalRef.current.focus();
    }
    return () => {
        setOpen(false)
    }
  }, []);

  const handleClose = () => {
    setShowVideoModal(false);
    setIsVideoMain(true);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableScrollLock={false}
      ref={modalRef}
    >
      <div
        id='modal-video-container'
        onClick={handleClose}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
          <button id='modal-video-back-btn' onClick={handleClose}>Back</button>
          <iframe id='video-iframe' src={src} allowFullScreen></iframe>
          <button id='modal-video-back-btn' onClick={handleClose}>Back</button>
      </div>
    </Modal>
  );
}