import React, {useState} from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './UiDetailList.css'
import './UiItemDetails.css'

const UiItemDetails = props => {
    const [isModelOptionsActive, setIsModelOptionsActive] = useState(false)
    const [modelOptionsStyle, setModelOptionsStyle] = useState('uiDetailList hide')
    const [isIncludedOpen, setIsIncludedOpen] = useState(false)
    const [includedStyle, setIsIncludedStyle] = useState('uiDetailList hide')

    const skuValidation = (item) => {
        if (item.includes(':')) {
            return 'model-options'
        }
        return 'model-options-subheader'
    }

    const handleModelOptionsClick = () => {
        if (isModelOptionsActive) {
            setModelOptionsStyle('uiDetailList hide')
            setIsModelOptionsActive(false)
        } else {
            setModelOptionsStyle('uiDetailList')
            setIsModelOptionsActive(true)
        }
    }

    const handleIncludedClick = () => {
        if (isIncludedOpen) {
            setIsIncludedStyle('uiDetailList hide')
            setIsIncludedOpen(false)
        } else {
            setIsIncludedStyle('uiDetailList')
            setIsIncludedOpen(true)
        }
    }

    return (
        <div className="item-details-div">
            <h3 className='item-details-header'>Product Details</h3>
            <ul className="uiDetailList">
                {props.details.map(item => {
                    return <li className='item-details-li'>{item}</li>
                })}
            </ul>
            <h3 className='item-details-header'>Description</h3>
            <p className='item-details-p'>{props.description}</p>
            <div className='model-options-div'>
                {props.options[0] !== '' ? <button type='button' className='dropdown-title' onClick={handleModelOptionsClick}>View Model Options</button> : null}
                {props.options[0] !== '' ? 
                <ul className={modelOptionsStyle}>
                    {props.options.map(item => {
                        return <li className={skuValidation(item)}>{item}</li>
                    })}
                </ul> : null}
            </div>
            {props.includes[0] !== '' ? <button type='button' className='dropdown-title' onClick={handleIncludedClick}>Items Included</button> : null}
            {props.includes[0] !== '' ? 
                <ul className={includedStyle}>
                    {props.includes.map(item => {
                        return <li className='item-details-li'>{item}</li>
                    })}
                </ul> : null}
        </div>
    )
}

export default UiItemDetails