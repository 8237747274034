import React from 'react'
import { Link } from 'react-router-dom'
// used to modify values
import { useDispatch } from 'react-redux'
// import actions
import { updateItemObject } from '../features/itemObject'
import './ProductGridItem.css'

const ProductGridItem = props => {

    const dispatch = useDispatch()

    const skuCount = () => {
        let skus = props.item.options.filter(item => item.includes(':'))
        return skus.length
    }

    return (
        <div className="productGridItem" onClick={() => window.scrollTo(0,0)}>
            <Link to={`/${props.sku}/item`} onClick={() => {
                dispatch(updateItemObject(props.item))
            }}>
                <div className='product-grid-item-img-div'>
                    <img className='product-grid-card-image skeleton' src={props.image} width='1200' height='600' loading='lazy' />
                </div>
                <p className='product-grid-card-name'>{props.name}</p>
                {/* <p className='product-card-sku'>{props.sku.toUpperCase()}</p> */}
                {/* <p className='product-card-sku-count'>{skuCount() === 1 ? `${skuCount()} model option` : skuCount() > 1 ? `${skuCount()} model options` : null}</p> */}
            </Link>
        </div>
    )
}

export default ProductGridItem