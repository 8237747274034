import React from 'react'
import ProductList from './ProductList'

import './SuggestedItems.css'

const SuggestedItems = (props) => {
    return (
        <div className='suggested-items-container'>
            <p className='suggested-items-header'>Suggested Products</p>
            <ProductList items={props.items} />
        </div>
    )
}

export default SuggestedItems