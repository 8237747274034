import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import { Link } from 'react-router-dom'
import productData from '../../assets/data/productData'
import './GroupCard.css'

const GroupCard = props => {

    // variable for using a media query
    const isNavSmall = useMediaQuery('(max-width: 599px)')
    const isNavMed = useMediaQuery('(max-width: 790px)')

    let items = []

    productData.forEach(item => {
        if (item.group === props.group) {
            items.push(item)
        } else {
            return
        }
    })

    return (
        <Link to={`/${props.category}/${props.subCategory}/${props.group}/groups`} className={isNavSmall ? 'group-card-div-sm' : isNavMed ? 'group-card-div-md' : 'group-card-div'}>
            <h1 className='group-card-title'>{props.group}</h1>
                <div className='group-card-grid'>
                    {items.map((item, index) => {
                        if (index < 3) {
                            return ([<div className='group-card-item'>
                                        <img className='group-card-item-img skeleton' src={item.image} alt={item.name} width='1200' height='600' loading='lazy' />
                                        <p className='group-card-item-name'>{item.name}</p>
                                    </div>])
                        } else {
                            return
                        }
                    })}
                </div>
        </Link>
    )
}

export default GroupCard