import React from 'react';
import ReactDOM from 'react-dom';
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux';
import itemReducer from './features/itemObject'
import App from './App';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles"
import ReactGA from 'react-ga4'

const ga_measurement_id = process.env.REACT_APP_MEASUREMENT_ID;
ReactGA.initialize(ga_measurement_id);
ReactGA.send({hitType: "pageview", page: window.location.href});

// Need a reducer for current item object
const store = configureStore({
    reducer: {
        item: itemReducer
    }
})

// Material UI
const theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            light: "#FFFFFF",
            main: "#FFFFFF",
            dark: "#1B2226"
        },
        secondary: {
            light: "#008CFF",
            main: "#373737",
            dark: "#0091ff"
        },
        background: {
            default: "#1B2226"
        }
    }
})

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <MuiThemeProvider theme={theme}>
                <App />
            </MuiThemeProvider>
        </Provider>
    </React.StrictMode>, 
    document.getElementById('root')
    );