import React from "react"
import { Icon } from "@material-ui/core"
import FisherLogo from "../../assets/images/fisher-logo-black.svg"

function Logo() {
    return (
        <Icon>
            <img style={{margin: "10px 0 0 0"}} src={FisherLogo} height={45} width={90} alt="Fisher Athletic Logo"/>
        </Icon>
    )
}

export default Logo