import React, { useEffect, useState } from "react"
import IntroSection from './subComponents/IntroSection'
import ContactForm from './subComponents/ContactForm'
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

import './Contact.css'

function Contact() {
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        document.title = `Contact - Fisher Athletic`
      })

    return(
        <div className='contact-div'>
            <div className='contact-header'>
                <IntroSection header='Inquiry Form' subHeader='Get in touch with us.' />
            </div>
            <div>
                <ContactForm />
            </div>
            <div className='contact-header'>
                <IntroSection header='Contact Information' subHeader='Send us an email or call one of our customer service reps.' />
            </div>
            <div id='contact-information-div'>
                <a href="#" className='contact-information-link' onClick={() => window.location = 'mailto:customerservice@fisherathletic.com'}><EmailIcon className='icon' /> CustomerService@FisherAthletic.com</a>
                <a href="tel:704-636-5713" className='contact-information-link'><PhoneIcon className='icon' />704-636-5713</a>
                <a href="tel:1-800-438-6028" className='contact-information-link'><PhoneIcon className='icon' />1-800-438-6028</a>
            </div>
        </div>
    )
}

export default Contact