let catalogData = [
    // {
    //     name: "Master",
    //     year: 2020,
    //     link: "https://e.issuu.com/anonymous-embed.html?u=fisherathleticequipment&d=2020mastercatalogforweb",
    //     image: "https://lh3.googleusercontent.com/BRQPOyzeEkKCEKl71oTpzdNoR-gCenr8w95ntOralnn9ZtGvsMEsY4Sh9MynsPLmm3h272gg7kBQO-rcik94ZrK_wrTgWu3IS-D3wlA8LgCdN0XpRrpAlwfA5c5djNzmx4aI8BBNFA=w233-h300-no"
    // },
    {
        name: "Football",
        year: 2025,
        link: "https://issuu.com/fisherathleticequipment/docs/2024_football_catalog-web?fr=sNjQ3MDY4NjM2OTg",
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F2024-football-cover-fix.jpg?alt=media&token=dddb8c9a-b30c-4d0a-b97a-4e8d9b4059bc"
    },
    {
        name: "Baseball",
        year: 2025,
        link: "https://issuu.com/fisherathleticequipment/docs/2024_baseball_dealer_5266f930c3c150?fr=sMjk4ZjY4NjM2OTg",
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F2025-baseball-cover.jpg?alt=media&token=0c26c2cf-062a-4238-94d8-467551518bfc"
    },
    {
        name: "Facility Branding",
        year: 2025,
        link: "https://issuu.com/fisherathleticequipment/docs/2024_facility_branding?fr=xKAE9_zU1NQ",
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffacility-branding-catalog-thumb-2025.jpg?alt=media&token=69d9e953-e4a6-4705-b356-035fc498e2a9"
    },
    {
        name: "Chairs & Stools",
        year: 2025,
        link: "https://issuu.com/fisherathleticequipment/docs/2024_chairs_stools-update-4-web?fr=sNTQ5ODY4NjM2OTg",
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F2025-chair-flyer-cover.jpg?alt=media&token=a91ac38b-8ab7-444f-92c7-f67e17046dda"
    },
    {
        name: "Protective Padding",
        year: 2025,
        link: "https://issuu.com/fisherathleticequipment/docs/2024_protectivepadding_267fa6c27d8d31?fr=sNTJhNTY4NjM2OTg",
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F2025-protective-padding-cover.jpg?alt=media&token=e3d0b172-35c4-492e-8e19-ce04db708043"
    },
    {
        name: "Track & Field",
        year: 2025,
        link: "https://issuu.com/fisherathleticequipment/docs/2024-track_field_dealer?fr=xKAE9_zU1NQ",
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftrack-field-catalog-thumb-2025.jpg?alt=media&token=4f1a3644-648d-4e36-9961-de40766beab3"
    }
    // {
    //     name: "PPE",
    //     year: 2020,
    //     link: "https://issuu.com/fisherathleticequipment/docs/2020_covid19_package_institute-no_fold-comp?fr=sYTMyNDM3MDQ0Mg",
    //     image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F2020-ppe-catalog-web-sm.jpg?alt=media&token=73e3694b-9dd8-4e3e-8f7e-4e42fdf73f72"
    // }
]

export default catalogData