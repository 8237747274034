import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import IntroSection from '../subComponents/IntroSection'
import productData from '../../assets/data/productData'
import ProductList from '../ProductList'

import './Groups.css'

const Groups = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    const paramsGroup = useParams().groups
    const paramsSubCategory = useParams().subCategory
    const paramsCategory = useParams().category
    
    const capitalizeFirstLetter = (str) => {
        let strArr = str.split(' ')
        let newStr = ''
        strArr.forEach((word, idx) => {
            let arr = word.split('')
            arr[0] = arr[0].toUpperCase()
            let newStr = arr.join('')
            strArr[idx] = newStr
        })
        newStr = strArr.join(' ')
        return newStr
    }

    const paramsSubCategoryCapital = capitalizeFirstLetter(paramsSubCategory)

    useEffect(() => {
        document.title = `${paramsGroup} - ${paramsSubCategoryCapital} - Fisher Athletic`
    })

    const urlConvertToTitle = input => {
        let title = input
        title = title.replace('&', ' & ')
        title = title.replace('+', ' ')
        return title
    }
    let loadedProducts
    loadedProducts = productData.filter(item => item.group.includes(paramsGroup) && item.category.toUpperCase().includes(paramsCategory.toUpperCase()))
    return (
        <div className='groups-div'>
            <IntroSection header={urlConvertToTitle(paramsGroup)} subHeader='All products under this group.' />
            <ProductList items={loadedProducts} inGroup={true} />
        </div>
    )
}

export default Groups