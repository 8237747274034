import React from 'react'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import { Link } from 'react-router-dom'

import './SocialMediaBar.css'

const SocialMediaBar = props => {
    return (
        <div className='social_media_bar_con'>
                <a href="https://www.facebook.com/fisherathletic" title="facebook" target="_blank" rel='noopener noreferrer'>
                    <p><FacebookIcon /></p>
                </a>
                <a href="https://twitter.com/fisher_athletic" title="twitter" target="_blank" rel='noopener noreferrer'>
                    <p><TwitterIcon /></p>
                </a>
                <a href="#" title="email">
                    <Link to={'/contact'}><p><EmailIcon /></p></Link>
                </a>
                <a href="tel:1-800-438-6028" title="phone" rel='noopener noreferrer'>
                    <p><PhoneIcon /></p>
                </a>
        </div>
    )
}

export default SocialMediaBar