let subCategoryData = [
    {
        id: 'c1-1',
        name: 'Sleds',
        urlName: 'Sleds',
        main: 'Football',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c1-2',
        name: 'Chutes',
        urlName: 'Chutes',
        main: 'Football',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c1-3',
        name: 'Dummies',
        urlName: 'Dummies',
        main: 'Football',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c1-4',
        name: 'Blasters',
        urlName: 'Blasters',
        main: 'Football',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c1-5',
        name: 'Gameday',
        urlName: 'Gameday',
        main: 'Football',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c1-6',
        name: 'Practice Items',
        urlName: 'Practice Items',
        main: 'Football',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c1-7',
        name: 'Strength & Speed',
        urlName: 'Strength and Speed',
        main: 'Football',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c1-8',
        name: 'Youth Football',
        urlName: 'Youth Football',
        main: 'Football',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c2-1',
        name: 'Windscreen',
        urlName: 'Windscreen',
        main: 'Baseball',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c2-8',
        name: 'On Deck Circles',
        urlName: 'On Deck Circles',
        main: 'Baseball',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c2-2',
        name: 'Fence Tops',
        urlName: 'Fence Tops',
        main: 'Baseball',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c2-3',
        name: 'Protective Padding',
        urlName: 'Protective Padding',
        main: 'Baseball',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c2-4',
        name: 'Field',
        urlName: 'Field',
        main: 'Baseball',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c2-5',
        name: 'Protective Screens',
        urlName: 'Protective Screens',
        main: 'Baseball',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c2-6',
        name: 'Training Aids',
        urlName: 'Training Aids',
        main: 'Baseball',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c2-7',
        name: 'Netting',
        urlName: 'Netting',
        main: 'Baseball',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c4-1',
        name: 'Chairs',
        urlName: 'Chairs',
        main: 'Chairs and Stools',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c4-2',
        name: 'Stools',
        urlName: 'Stools',
        main: 'Chairs and Stools',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c4-3',
        name: 'Swag Mats',
        urlName: 'Swag Mats',
        main: 'Chairs and Stools',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c4-4',
        name: 'Chair Carts',
        urlName: 'Chair Carts',
        main: 'Chairs and Stools',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c6-1',
        name: 'Outdoor Padding',
        urlName: 'Outdoor Padding',
        main: 'Protective Padding',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c6-3',
        name: 'Indoor Padding',
        urlName: 'Indoor Padding',
        main: 'Protective Padding',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c5-1',
        name: 'Adhesive Graphics',
        urlName: 'Adhesive Graphics',
        main: 'Facility Branding',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c5-2',
        name: 'Floor',
        urlName: 'Floor',
        main: 'Facility Branding',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c5-3',
        name: 'Signage',
        urlName: 'Signage',
        main: 'Facility Branding',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c5-4 ',
        name: 'Banners',
        urlName: 'Banners',
        main: 'Facility Branding',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c7-1 ',
        name: 'Gym Accessories',
        urlName: 'Gym Accessories',
        main: 'Gym',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c7-2 ',
        name: 'Training Items',
        urlName: 'Training Items',
        main: 'Gym',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c9-1 ',
        name: 'Gymnastic Mats',
        urlName: 'Gymnastic Mats',
        main: 'Training Mats',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c9-2 ',
        name: 'Personal Mats',
        urlName: 'Personal Mats',
        main: 'Training Mats',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c9-3 ',
        name: 'Landing Mats',
        urlName: 'Landing Mats',
        main: 'Training Mats',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c3-1 ',
        name: 'Pits',
        urlName: 'Pits',
        main: 'Track and Field',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c3-2 ',
        name: 'Track Equipment',
        urlName: 'Track Equipment',
        main: 'Track and Field',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    },{
        id: 'c3-3 ',
        name: 'Field Equipment',
        urlName: 'Field Equipment',
        main: 'Track and Field',
        coverImg: 'https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimage-coming-soon.jpg?alt=media&token=e4f1e90a-0052-459f-829c-57c5471e3415'
    }
]

export default subCategoryData