import React from 'react'
import TopNav from './components/subComponents/TopNav'
import Home from "./components/Home"
import Products from "./components/Products"
import Catalogs from "./components/Catalogs"
import Contact from "./components/Contact"
import Categories from './components/pages/Categories'
import { useMediaQuery } from "@material-ui/core"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import SubCategories from './components/pages/SubCategories'
import Groups from './components/pages/Groups'
import Item from './components/pages/Item'
import Search from './components/Search'
import Footer from './components/Footer'
import Downloads from './components/Downloads'
import Policies from './components/Policies'
import './App.css'

function App() {

    // variable for using a media query
    const isNavSmall = useMediaQuery('(max-width: 599px)')

    return (
      <Router>
        <TopNav />
            <div>
              <Switch>
              <Route exact path="/"><Home /></Route>
              <Route exact path="/catalogs"><Catalogs /></Route>
              <Route exact path="/contact"><Contact /></Route>
              <Route exact path="/categories"><Categories /></Route>
              <Route exact path="/:category/subCategories"><SubCategories /></Route>
              <Route exact path="/:category/:query/products"><Products /></Route>
              {/* <Route exact path="/:products/products"><Products/></Route> */}
              <Route exact path="/:category/:subCategory/:groups/groups"><Groups /></Route>
              <Route exact path="/:item/item"><Item /></Route>
              <Route exact path="/search"><Search /></Route>
              <Route exact path='/downloads'><Downloads /></Route>
              <Route exact path='/policies'><Policies /></Route>
              <Route exact path='/index.html'><Redirect to="/" /></Route>
              <Route path="*"><Redirect to="/" /></Route>
              </Switch>
            </div>
        <Footer class='footer_container' />
      </Router>
    )
}

export default App;