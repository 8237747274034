import React from 'react'
import { Link } from 'react-router-dom'

const CategoryItem = props => {
    const handleLink = (url) => {
        if (url === 'Windscreen') {
            return `/Baseball/Windscreen/products`
        }
        return `/${url}/subcategories`
    }

    return (
        <Link to={handleLink(props.urlName)}>
            <li>
                <h3 className='category-title'>{props.name}</h3>
            </li>
        </Link>
    )
}

export default CategoryItem