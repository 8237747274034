import React, { useEffect } from "react"
import { useParams } from 'react-router-dom'

import ProductList from './ProductList'
import productData from '../assets/data/productData'
import IntroSection from './subComponents/IntroSection'

import './Products.css'

const Products = props => {

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    const { category, query } = useParams()

    useEffect(() => {
        document.title = `${query} - ${category} - Fisher Athletic`
      })

    const urlMinusEndChar = query.charAt(query.length - 1).toUpperCase() === 'S' ? query.slice(0, -1) : query
    const urlNoChar = query.replace(/\D/g, '')
    // const urlSpaceToPlus = query.replace(/ /g, '+')
    const isSku = productData.some(item => item.sku.toUpperCase().includes(query.toUpperCase()))
    const isSkuNum = /\d/.test(query) ? productData.some(item => item.sku.toUpperCase() === urlNoChar.toUpperCase()) : false
    const isName = productData.some(item => item.name.toUpperCase().includes(urlMinusEndChar.toUpperCase()))
    const isCategory = productData.some(item => item.category.toUpperCase() === (query.toUpperCase()))
    const isSubcategory = productData.some(item => item.subCategory.toUpperCase().includes(query.toUpperCase()))
    const isAll = query.includes('+all')
    let allCategory

    if (isAll) {
        allCategory = query.replace('+all', '')
    }

    let loadedProducts = []
    let output

    // algorithm for searching products
    if (category === 'search') {
        loadedProducts = productData.filter(item => item.sku.toUpperCase() === query.toUpperCase())
        loadedProducts = loadedProducts.concat(productData.filter(item => item.options.join().toUpperCase().includes(query.toUpperCase())))
        loadedProducts = loadedProducts.concat(productData.filter(item => item.sku.toUpperCase() === urlNoChar.toUpperCase()))
        loadedProducts = loadedProducts.concat(productData.filter(item => item.category.toUpperCase() === query.toUpperCase()))
        loadedProducts = loadedProducts.concat(productData.filter(item => item.subCategory.toUpperCase().includes(query.toUpperCase())))
        loadedProducts = loadedProducts.concat(productData.filter(item => item.name.toUpperCase().includes(urlMinusEndChar.toUpperCase())))
        // if the search finds nothing... search by individual words, one at a time
        if (loadedProducts.length === 0) {
            let capitalQuery = query.toUpperCase()
            let queryArr = capitalQuery.split(' ')
            queryArr.forEach(item => {
                let queryItem = item
                let queryMinusS = queryItem.charAt(queryItem.length - 1) === 'S' ? queryItem.slice(0, -1) : queryItem
                loadedProducts = loadedProducts.concat(productData.filter(item => item.sku.toUpperCase() === queryItem))
                loadedProducts = loadedProducts.concat(productData.filter(item => item.options.join().toUpperCase().includes(queryItem)))
                loadedProducts = loadedProducts.concat(productData.filter(item => item.category.toUpperCase().includes(queryItem)))
                loadedProducts = loadedProducts.concat(productData.filter(item => item.subCategory.toUpperCase().includes(queryItem)))
                loadedProducts = loadedProducts.concat(productData.filter(item => item.name.toUpperCase().includes(queryMinusS)))
            })
        }
        
    } else if (isAll) {
        loadedProducts = productData.filter(item => item.urlCategory === allCategory)
        output = "isAll"
    } else if (isSku) {
        loadedProducts = productData.filter(item => item.sku.toUpperCase().includes(query.toUpperCase()))
        loadedProducts = loadedProducts.concat(productData.filter(item => item.options.join().toUpperCase().includes(query.toUpperCase())))
        output = "isSku"
    } else if (isSkuNum) {
        loadedProducts = productData.filter(item => item.sku.toUpperCase().includes(urlNoChar.toUpperCase()))
        loadedProducts = loadedProducts.concat(productData.filter(item => item.options.join().toUpperCase().includes(query.toUpperCase())))
        output = "isSkuNum"
    } else if (isCategory) {
        loadedProducts = productData.filter(item => item.category.toUpperCase().includes(query.toUpperCase()))
        output = "isCategory"
    } else if (isSubcategory) {
        loadedProducts = productData.filter(item => item.subCategory.toUpperCase().includes(query.toUpperCase()))
        output = "isSubcategory"
    } else if (isName) {
        loadedProducts = productData.filter(item => item.name.toUpperCase().includes(urlMinusEndChar.toUpperCase()))
        output = "isName"
    } else {
        loadedProducts = productData.filter(item => item.subCategory.toUpperCase().includes(query.toUpperCase()))
        loadedProducts = loadedProducts.concat(productData.filter(item => item.description.toUpperCase().includes(query.toUpperCase())))
        loadedProducts = loadedProducts.concat(productData.filter(item => item.options.join().toUpperCase().includes(query.toUpperCase())))
        output = "else"
    }

    // removes duplicates from the array
    loadedProducts = [...new Set(loadedProducts)]

    let loadedProductsNoSearch = loadedProducts.filter(item => item.category.toUpperCase().includes(category.toUpperCase()))

    let itemOutput = category === 'search' ? loadedProducts : loadedProductsNoSearch

    const renderHeader = itemOutput.length === 0 ? null : <IntroSection header={`${query}`} subHeader={`Find amazing products from Fisher Athletic.`} />

    return (
        <div className='products-div'>
            {renderHeader}
            <ProductList query={query} items={itemOutput} inGroup={false} isSearch={category === 'search' ? true : false} subCategory={query.toUpperCase()} />
        </div>
    )
}

export default Products