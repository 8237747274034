import React from 'react'
import './ProductTrain.css'
import IntroSection from './subComponents/IntroSection'
import ProductList from '../components/ProductList'
import ProductGrid from '../components/ProductGrid'

const ProductTrain = (props) => {
    return (
        <div class='product-train-container'>
            <IntroSection header={props.header} subHeader={props.subHeader} />
            {/* items prop should be an array of the object/s from productData.js */}
            <ProductGrid items={props.items} />
        </div>
    )
}

export default ProductTrain