import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SubCategoryList from '../subComponents/SubCategoryList'
import subCategoryData from '../../assets/data/subCategoryData'
import IntroSection from '../subComponents/IntroSection'

import './SubCategories.css'

const urlConvertToTitle = input => {
    let title = input
    title = title.replace('&', ' & ')
    title = title.replace('+', ' ')
    return title
}

const SubCategories = props => {

    const { category } = useParams()

    useEffect(() => {
        document.title = `${category} - Fisher Athletic`
      })
    
    useEffect(() => {
        window.scrollTo(0 ,0)
    },[])

    const loadedSubCategories = subCategoryData.filter(item => (item.main.toUpperCase() === category.toUpperCase()))
    return (
        <div className='subcategories-div'>
            <IntroSection header={urlConvertToTitle(category)} subHeader={`Choose a subcategory.`} />
            <SubCategoryList items={loadedSubCategories} category={category} />
        </div>
    )
}

export default SubCategories