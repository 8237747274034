import React, { useEffect } from 'react'
import IntroSection from './subComponents/IntroSection'
import DocumentGrid from './DocumentGrid'
import documentData from '../assets/data/documentData'

const Downloads = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0)
    })

    useEffect(() => {
        document.title = `Downloads | Fisher Athletic`
    })

    return (
        <div>
            <IntroSection header="Downloads" subHeader="Need to download a certain file? Find it here." />
            <DocumentGrid documents={documentData} />
        </div>
    )
}

export default Downloads