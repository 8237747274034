import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import './DocumentCard.css'

const DocumentCard = (props) => {
    return (
        <div className='document-card-lg'>
            <a href={props.url} target='_blank'>
                <img className='document-card-image skeleton' src={props.image} alt={props.title} width='460' height='600'></img>
            </a>
            <p className='document-card-title'>{props.title}</p>
        </div>
    )
}

export default DocumentCard