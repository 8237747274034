import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import IntroSection from './subComponents/IntroSection'
import productData from '../assets/data/productData'
import SearchIcon from '@material-ui/icons/Search';
import ReactGA from 'react-ga4'

import './Search.css'

const Search = props => {
    const [searchInput, setSearchInput] = useState('')

    useEffect(() => {
        document.title = `Search - Fisher Athletic`
      })

    const handleInput = event => {
        setSearchInput(event.target.value)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        ReactGA.event({
            category: "searches",
            action: "query",
            label: searchInput
          });
        if(searchInput === '' || searchInput === ' ') {
            console.log('Please type something in the search bar.')
        } else {
            window.location.href=`/search/${searchInput}/products`
        }
    }
    
    const renderSuggestions = productData.map(item => {
        let name = item.name.toUpperCase()
        let sku = item.sku.toUpperCase()
        let group = item.group.toUpperCase()
        let subcategory = item.subCategory.toUpperCase()
        subcategory = subcategory.replace(/\+/g, ' ')
        let category = item.category.toUpperCase()
        category = category.replace(/\+/g, ' ')
        let search = searchInput.toUpperCase()
        search = search.charAt(search.length - 1).toUpperCase() === 'S' ? search.slice(0, -1) : search

        if (search.length <= 1) {
            return null
        } else if (search === '' || search === ' ') {
            return null
        } else if (name.includes(search) || sku.includes(search) || group.includes(search) || subcategory.includes(search) || category.includes(search)) {
            return <Link key={item.id} className='suggestion-link' to={`/${item.sku}/item`}><SearchIcon fontSize='inherit' className='suggestion-search-icon' />{item.name}</Link>
        } else {
            return null
        }
    })

    return (
        <div className='search-div'>
            <IntroSection header="Search" subHeader="Fisher Athletic products." />
            <form className="search-form" autoComplete="off" onSubmit={handleSubmit}>
                <input className="search-input" type="text" name="searchInput" autoFocus="autofocus" onChange={handleInput} />
                    <button className="search-btn" type="submit">search</button>
            </form>
            <div className='search-suggestions-div'>
                {renderSuggestions}
            </div>
        </div>
    )
}

export default Search