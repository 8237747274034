import React, { useEffect} from 'react'

import './Catalog.css'

const Catalog = props => {

    useEffect(() => {
        document.title = `Catalogs - Fisher Athletic`
    })

    return (
        <div className="catalog_item">
            <a href={props.link} rel="noopener noreferrer" target="_blank">
                <img className="catalog_image skeleton" src={props.image} alt={props.name} width='460' height='600' />
                <p id='catalog-name'>{props.name}</p>
            </a>
        </div>
    )
}

export default Catalog